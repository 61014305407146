<template>
  <page-main
    title="Let's get the basics about your higher education"
    :aside="[
      'Covering the basic information, including your current status.',
      'If you don\'t want to list your GPA, feel free to leave those fields blank.',
    ]"
    nextText="We'll get the details of your degree."
    buttonTabIndex="16"
    eventName="nav"
    @nav="nav('/EducationEntryDegree/' + key)"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="universityName"
            label="University or College Name"
            tabindex="1"
            autofocus
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="schoolName"
            label="School Name (within University - if applicable)"
            tabindex="2"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="universityCity"
            label="City"
            tabindex="3"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="universityState"
            label="State"
            tabindex="4"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="country"
            label="Country"
            tabindex="5"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-switch
            v-model="graduated"
            label="Have you graduated?"
            tabindex="6"
          ></r-switch>
        </v-col>
        <v-col cols="12" class="r-col">
          <month-date-select
            label="Date Started"
            :value.sync="dateStart"
            tabindex="7"
          ></month-date-select>
        </v-col>
        <transition name="line-82-swap">
          <v-col cols="12" class="r-col" v-if="graduated">
            <month-date-select
              label="Date Completed"
              :value.sync="dateEnd"
              tabindex="9"
            ></month-date-select>
          </v-col>
        </transition>
        <transition name="line-82-swap">
          <v-col cols="12" class="r-col" v-if="!graduated">
            <r-switch
              v-model="hasExpectedGrad"
              label="Have an expected graduation date?"
              tabindex="11"
              class="max-h-82"
            >
            </r-switch>
          </v-col>
        </transition>
        <transition name="line-82">
          <v-col cols="12" class="r-col" v-if="hasExpectedGrad && !graduated">
            <month-date-select
              label="Expected Graduation Date"
              :value.sync="dateExpected"
              tabindex="12"
              :extraYears="6"
            ></month-date-select>
          </v-col>
        </transition>
        <v-col cols="12" class="r-col">
          <r-text-field v-model="gpa" label="GPA" tabindex="14"></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="gpaScale"
            label="GPA Scale (ie 4.0, 5.0)"
            tabindex="15"
          ></r-text-field>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

// @ is an alias to /src
export default {
  name: "EducationEntryGeneral",
  data: () => ({
    currentDisplay: "Present",
  }),
  components: {},
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }
  },
  methods: {
    save() {
      this.saveEducation();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapMutations("education", ["createEntry"]),
    ...mapActions("education", ["saveEducation"]),
  },
  computed: {
    ...mapGetters("education", ["doesKeyExist"]),
    ...mapFields("education", {
      key: "key",
      eduDisplay: "educationGeneral.display",
      universityName: "educationGeneral.universityName",
      schoolName: "educationGeneral.schoolName",
      universityCity: "educationGeneral.universityCity",
      universityState: "educationGeneral.universityState",
      country: "educationGeneral.country",
      graduated: "educationGeneral.graduated",
      dateStart: "educationGeneral.dateStart",
      dateEnd: "educationGeneral.dateEnd",
      hasExpectedGrad: "educationGeneral.hasExpectedGrad",
      dateExpected: "educationGeneral.dateExpected",
      gpaDisplay: "educationGPA.display",
      gpa: "educationGPA.gpa",
      gpaScale: "educationGPA.gpaScale",
    }),
  },
};
</script>

<style>
/* The month-date-select control is 82 px high */
.max-h-82 {
  max-height: 82px;
}
</style>
